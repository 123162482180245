@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500;600;700;800&family=Share+Tech+Mono&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 12px;
    font-weight: 300;
}
.lp {
    font-family: 'Share Tech Mono', monospace;
}
.df {
    font-family: 'Josefin Sans', sans-serif;
}
.text-font{
    font-family: 'Calibri', sans-serif;
}
.App {
    height: calc(100vh);
}

h1 {
    color: #8b008b;
    font-weight: 300;
    font-size: 5.5rem;
}

h2 {
    color: #8b008b;
    font-weight: 400;
    font-size: 3.5rem;
}

.LazyLoad {
    opacity: 0;
    transition: all 1s ease-in-out;
}

.is-visible {
    opacity: 1;
}
.btn-purple {
    background-color: #891689d6;
    color: #fff;
}
.btn-purple:hover, .btn-purple:active, .btn-purple:focus {
    background-color: #8b008b;
    color: #fff;
}
.steps li{
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 26px;
    font-weight: 500;
    font-size: 13px;
    margin-right: 8px;
    transition: all ;
    color: #fff;
    -webkit-transition: all 500ms linear;
-moz-transition: all 500ms linear;
-ms-transition: all 500ms linear;
-o-transition: all 500ms linear;
transition: all 500ms linear;
}
@media only screen and (min-width: 768px) {
    .video-box{
        margin-left: 17rem;
        margin-right: 17rem;
    }
}
.text-content strong{
    color:#000000;
    font-weight: bold;
}

ul.circle { list-style-type: disc; }

ol.la {
    list-style-type: lower-alpha;
}
ol.ua {
    list-style-type: upper-alpha;
}
ol.numeric{
    list-style-type: decimal;
}

table.border, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
    width:33%
}
table.border {
    width: 50%;
}
table.cst-tbl{
    width: 40%;
    margin: auto;
}
table.cst-tbl thead{
    background: #000;
    color: #fff;
}
.text-left{
    text-align: left;
}
.p-right{
    float: right;
}
.clearfix{
    clear: both;
}
.title-3{
    font-size: 18px;
    color: #000;
    font-weight: bold;
}
.wh-50{
    width: 50%
}
table.tbl-full{
    width: 100%;
}
@media only screen and (min-width: 768px){
    .video-single {
        margin-left: 14rem;
        margin-right: 14rem;
    }
}